import React from "react";

function ServicesHomeTwo({ className }) {
  return (
    <>
      <section
        className={`appie-services-2-area pb-100 ${className}`}
        id="service"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i className="fas fa-bolt" />
                </div>
                <h4 className="title">Transformational SDLC</h4>
                <p>
                  We optimize the entire SDLC for peak efficiency and success.
                </p>
                {/* <a href="/">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="icon">
                  <i className="fas fa-home" />
                </div>
                <h4 className="title">On-Site Professionals</h4>
                <p>
                  We provide skilled on-site specialists for your project's
                  specific needs.
                </p>
                {/* <a href="/">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="icon">
                  <i className="fas fa-link" />
                </div>
                <h4 className="title">Culture Establishment</h4>
                <p>
                  We shape a culture aligning with your project's and company
                  vision.
                </p>
                {/* <a href="/">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i className="fas fa-bell" />
                </div>
                <h4 className="title">Code Implementation</h4>
                <p>
                  We write tailored code, adhering to your project's standards.
                </p>
                {/* <a href="/">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="icon">
                  <i className="fas fa-cog" />
                </div>
                <h4 className="title">Infrastructure Improvement</h4>
                <p>We enhance your infrastructure to boost app performance.</p>
                {/* <a href="/">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="icon">
                  <i className="fas fa-lock" />
                </div>
                <h4 className="title">Department Training</h4>
                <p>
                  We train your IT department in the latest coding techniques.
                </p>
                {/* <a href="/">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesHomeTwo;
