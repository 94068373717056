import React from "react";

function HeroAbout() {
  return (
    <>
      <div className="appie-about-top-title-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="appie-about-top-title">
                <h3 className="title">Innovate. Empower. Transform</h3>
                <p>Unleashing the power of technology for a better tomorrow.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="appie-about-page-area ">
        <div className="container">
          <div className="row justify-content-left">
            <div className="col-lg-8">
              <div className="appie-about-page-content">
                {/* <center> */}
                <h3 className="title">Our Journey and Commitment</h3>
                <p>
                  Cqudefus LLC, a South African multinational technology company
                  and proud subsidiary of FirstEleven Inc., is on a
                  transformative journey that extends beyond the borders of
                  Africa. Specializing in software development, artificial
                  intelligence, machine learning, robotics, and computer
                  software, our commitment to innovation knows no bounds. Our
                  name, "Cqudefus," encapsulates our core values:{" "}
                  <strong>Creativity, Quality, Detail, Fun, and Speed.</strong>
                </p>
                <p>
                  Cqudefus strives to tread new ground by developing
                  cutting-edge, user-friendly technology solutions that have a
                  long-term global influence. As a vital part of the FirstEleven
                  family, we actively help to shape the global tech scene. Our
                  aim includes inventing ground-breaking technologies that
                  improve people's lives around the world and set new industry
                  standards.
                </p>
                <p>
                  Our team values flexibility and remote collaboration, allowing
                  members to work from anywhere in the world, fostering diverse
                  perspectives and ideas. We champion a mindset of continuous
                  improvement, user focus, simplicity in excellence, creating
                  awesome things, forward-thinking, and an obsession with
                  achieving excellence in all that we do.
                </p>
                <p>
                  Cqudefus is committed to bringing about positive change
                  through technology, with a goal of revolutionizing industries,
                  empowering communities, and shaping a brighter future for not
                  only Africa but the entire globe. We use the collaborative
                  power of creativity, quality, attention to detail, fun, and
                  speed to make a global difference.
                </p>
                {/* <a href="/">
                  View all Members <i className="fal fa-arrow-right"></i>
                </a> */}
                {/* </center> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroAbout;
