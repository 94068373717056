import React from "react";
import shape13 from "styles/images/shape/shape-13.png";
import shape14 from "styles/images/shape/shape-14.png";
import shape15 from "styles/images/shape/shape-15.png";
import trafficThumb from "styles/images/traffic-thumb-2.png";

function FeaturesHomeFour() {
  return (
    <>
      <section
        className="appie-features-area-2 appie-features-area-5 pt-90 pb-100"
        id="features"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">
                  What Sets Cqudefus Apart ?
                  <br />
                  Our Unique Approach
                </h3>
                <p>
                  Cqudefus' unique methods create impactful experiences in all
                  endeavors.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-30 align-items-center">
            <div className="col-lg-6">
              <div className="appie-features-boxes ">
                <div className="appie-features-box-item appie-features-box-5-item">
                  <h4 className="title">Creative Solutions</h4>
                  <p>Innovative strategies that boost your digital journey.</p>
                </div>
                <div className="appie-features-box-item item-2 appie-features-box-5-item">
                  <h4 className="title">User-Centric Approach</h4>
                  <p>Prioritizing users to craft resonant experiences.</p>
                </div>
                <div className="appie-features-box-item item-3 appie-features-box-5-item">
                  <h4 className="title">Strategic Thinking</h4>
                  <p>
                    Overcoming challenges strategically to get the best
                    outcomes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="appie-features-thumb wow animated fadeInRight"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <img src={trafficThumb} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="features-shape-1">
          <img src={shape13} alt="" />
        </div>
        <div className="features-shape-2">
          <img src={shape14} alt="" />
        </div>
        <div className="features-shape-3">
          <img src={shape15} alt="" />
        </div>
      </section>
    </>
  );
}

export default FeaturesHomeFour;
