import React from "react";
import thumb from "styles/images/service-details-thumb.jpg";

function DetailsService() {
  return (
    <>
      <section className="appie-service-details-area pt-0 pb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="service-details-content">
                <div className="thumb">
                  <img src={thumb} alt="" />
                </div>
                <div className="content">
                  <center>
                    <h3 className="title">
                      How Our Value-Based Consultation Works
                    </h3>

                    <p>
                      Cqudefus' value-based consulting service provides unique
                      solutions for your organization's success. We customize
                      our approach to meet your organization's specific goals
                      and provide practical solutions that go above the
                      ordinary. We examine your vision and goals and offer
                      hands-on help throughout your project.
                    </p>
                    <span>
                      We tailor our approach to your specific objectives,
                      whether it's optimizing your SDLC, training your team,
                      establishing a new culture, developing code, or enhancing
                      your infrastructure.
                    </span>
                    <p>
                      We collaborate with you to optimize your project's
                      efficiency and success. Cqudefus provides personalized
                      guidance and support at every step of the way.
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsService;
