import React from "react";

function Footer() {
  return (
    <>
      <section className="appie-footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="
                footer-copyright
                
                align-items-center
                justify-content-between
                pt-35
              "
              >
                <div className="copyright-text">
                  <center>
                    <p>
                      Copyright © {new Date().getFullYear()}{" "}
                      <a
                        href="https://www.firsteleven.co.za/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FirstEleven Inc.
                      </a>{" "}
                      All rights reserved.
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
