import React from "react";
import useToggle from "../../hooks/useToggle";
import BackToTop from "lib/BackToTop";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";
import Drawer from "../Mobile/Drawer";
import HeroNews from "../News/HeroNews";
import Forms from "../../components/Forms/Forms";
function Contact() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header drawer={drawer} action={drawerAction.toggle} />
      <HeroNews
        title="Contact"
        breadcrumb={[
          { link: "/", title: "home" },
          { link: "/contact", title: "Contact" },
        ]}
      />
      <Forms />
      <Footer />
      <BackToTop />
    </>
  );
}

export default Contact;
