import React from "react";
import IconOne from "styles/images/icon/1.png";
import IconTwo from "styles/images/icon/2.png";
import IconThree from "styles/images/icon/3.png";
import IconFour from "styles/images/icon/4.png";

function ServicesHomeOne({ className }) {
  return (
    <section
      className={`appie-service-area pt-90 pb-100 ${className}`}
      id="service"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="appie-section-title text-center">
              <h3 className="appie-title">Our Expertise at Cqudefus</h3>
              <p>
                Experience a range of innovative solutions for an exceptional
                journey.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="icon">
                <img src={IconOne} alt="" />
                <span>1</span>
              </div>
              <h4 className="appie-title">Software Engineering</h4>
              <p>
                Experts in cutting-edge software engineering for robust,
                scalable solutions.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="icon">
                <img src={IconTwo} alt="" />
                <span>2</span>
              </div>
              <h4 className="appie-title">App Development</h4>
              <p>
                Focused on mobile app development for innovative, user-friendly
                solutions.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="icon">
                <img src={IconThree} alt="" />
                <span>3</span>
              </div>
              <h4 className="appie-title">IT Consulting</h4>
              <p>
                Cqudefus provides IT consulting, aiding businesses in
                tech-driven growth.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div className="icon">
                <img src={IconFour} alt="" />
                <span>4</span>
              </div>
              <h4 className="appie-title">Digital Transformation</h4>
              <p>
                Facilitates digital adaptation and integrates competitive
                technology for organizations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesHomeOne;
