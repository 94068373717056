import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from "pages/about-us";
import Contact from "pages/Contact";
import Error from "components/Error";
import Loader from "lib/Loader";
import ScrollToTop from "lib/ScrollToTop";
import Home from "pages/Home";
import About from "pages/About";
import UnderConstruction from "pages/underConstruction";

function FileRoutes() {
  const [loading, setLoading] = useState(false); // Turn on underMaintenance to show maintenance page (true:off, false:on)
  const [underconstruction] = useState(false); // Turn on underMaintenance to show maintenance page (true:on, false:off)

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });
  return (
    <>
      {loading && (
        <div className={`appie-loader ${loading ? "active" : ""}`}>
          <Loader />
        </div>
      )}
      {!underconstruction ? (
        <div className={`appie-visible ${loading === false ? "active" : ""}`}>
          <BrowserRouter>
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<AboutUs />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<Error />} />
                <Route component={Error} />
              </Routes>
            </ScrollToTop>
          </BrowserRouter>
        </div>
      ) : (
        <UnderConstruction />
      )}
    </>
  );
}

export default FileRoutes;
