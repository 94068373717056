import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <>
      <ul>
        <li>
          <a href="/">
            Home
            {/* <i className="fal fa-angle-down" /> */}
          </a>
          {/* <ul className="sub-menu">
            <li>
              <a
                href="https://www.bible.cqudefus.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Bible
              </a>
            </li>
          </ul> */}
        </li>
        <li>
          <Link to="/about-us">About Us</Link>
        </li>
        {/* <li>
          <Link to="/services">Firsteleven</Link>
        </li> */}
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </>
  );
}

export default Navigation;
