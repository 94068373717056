import React, { useEffect } from "react";
import useToggle from "../../hooks/useToggle";
import StickyMenu from "../../lib/StickyMenu";
import BackToTop from "lib/BackToTop";
import Drawer from "../Mobile/Drawer";
import DetailsService from "../../components/Services/DetailsService";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import Services from "../../components/Services/ServicesThree";
import Hero from "../../layout/Hero/HeroThree";

function Home() {
  useEffect(() => {
    StickyMenu();
  });
  const [drawer, drawerAction] = useToggle(false);
  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header drawer={drawer} action={drawerAction.toggle} />;
      <Hero />
      <Services className="pt-30" />
      <DetailsService />
      <Footer />
      <BackToTop />
    </>
  );
}

export default Home;
