import React from "react";
import heroThumb from "styles/images/hero-thumb-7.png";

function HeroHomeSix() {
  return (
    <>
      <section className="appie-hero-area appie-hero-6-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="appie-hero-thumb-6">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="1000ms"
                  data-wow-delay="600ms"
                >
                  <img src={heroThumb} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-hero-content appie-hero-content-6">
                <span>Customized Consultation Services.</span>
                <h3 className="appie-title">
                  Enhance Your Development with Cqudefus
                </h3>
                <p>
                  Tailored consulting to enhance development. Collaborate for
                  better coding, efficient solutions, and project success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroHomeSix;
