import React from "react";
import counterIconOne from "styles/images/icon/counter-icon-1.svg";
import counterIconTwo from "styles/images/icon/counter-icon-2.svg";
import counterIconThree from "styles/images/icon/counter-icon-3.svg";
import counterIconFour from "styles/images/icon/counter-icon-4.svg";
import CounterUpCom from "lib/CounterUpCom";

function CounterArea({ style }) {
  return (
    <>
      <section
        className="appie-counter-area pt-40 pb-190"
        id="counter"
        style={style}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title">
                <center>
                  <h3 className="appie-title">Our user feedback loop</h3>
                  <p>
                    We adopt a user-centric, lean methodology where user
                    feedback is given priority.
                  </p>
                </center>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="counter-content">
                  <div className="icon">
                    <img src={counterIconOne} alt="" />
                  </div>
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom endValue={234} sectionSelect="counter" />
                    </span>
                    +
                  </h3>
                  <p>Active Installation</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-2 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="counter-content">
                  <div className="icon">
                    <img src={counterIconTwo} alt="" />
                  </div>
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom endValue={148} sectionSelect="counter" />
                    </span>
                    +
                  </h3>
                  <p>Positive Reactions</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-3 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="counter-content">
                  <div className="icon">
                    <img src={counterIconThree} alt="" />
                  </div>
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom endValue={187} sectionSelect="counter" />
                    </span>
                    +
                  </h3>
                  <p>Feedback from Users</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-4 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="counter-content">
                  <div className="icon">
                    <img src={counterIconFour} alt="" />
                  </div>
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom endValue={325} sectionSelect="counter" />
                    </span>
                    +
                  </h3>
                  <p>Overall User Satisfaction</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CounterArea;
