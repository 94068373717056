import React, { useState } from "react";

function FaqHomeOne({ className }) {
  const [showQues, setQues] = useState(1);
  const openQuestion = (value) => {
    setQues(value);
  };
  return (
    <>
      <section className={`appie-faq-area pb-95 pt-60 ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Frequently Asked Questions</h3>
                <p>Common Queries About Cqudefus and Its Services</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(1)}
                    className={`accrodion ${showQues === 1 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>
                          How can Cqudefus help my business with digital
                          transformation?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 1 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Cqudefus assists businesses in leveraging modern
                            technologies to streamline operations, enhance
                            customer experiences, and drive innovation. Our team
                            of experts will work with you to develop a tailored
                            digital transformation strategy that aligns with
                            your business goals.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(2)}
                    className={`accrodion ${showQues === 2 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>What industries does Cqudefus specialize in?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 2 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Cqudefus has experience across various industries,
                            including finance, healthcare, retail,
                            manufacturing, and more. Our consultants have the
                            expertise to address the unique challenges and
                            opportunities within your specific industry.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(3)}
                    className={`accrodion ${showQues === 3 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>Can Cqudefus provide on-site support?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 3 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Yes, Cqudefus offers on-site support to ensure
                            seamless project execution. Our professionals can
                            work at your location for the duration of the
                            project, providing hands-on assistance and expertise
                            as needed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(4)}
                    className={`accrodion ${showQues === 4 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4>What is the process for engaging with Cqudefus?</h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 4 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            To engage with Cqudefus, you can reach out to us
                            through our contact page or{" "}
                            <a href="mailto:info@cqudefus.com">email us</a>. We
                            will arrange an initial consultation to understand
                            your needs and propose a customized solution that
                            aligns with your business objectives.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              {/* <div className="faq-text text-center pt-40">
                <p>
                  Can't find an answer?{" "}
                  <a href="mailto:info@cqudefus.com">Email us</a>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqHomeOne;
