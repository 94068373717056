import image from 'styles/images/svg/uc.png';
import logo from 'styles/images/heavyloadLogo2.png';

const UnderConstruction = () => {
  return (
    <div className='overflow-hidden'>
      <header className='appie-header-area appie-header-8-area appie-sticky '>
        <div className='container'>
          <div className='header-nav-box header-nav-box-6'>
            <div className='row align-items-center'>
              <div className='col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1'>
                <div className='appie-logo-box'>
                  <img src={logo} alt='heavyload logo' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section
        className='appie-hero-area appie-hero-8-area'
        style={{ padding: '200px 20px 50px 20px' }}
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='appie-hero-content appie-hero-content-8'>
                <h1 className='appie-title'>Website under maintenance</h1>
                <p>We have a “strategic” plan its called doing things.</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='appie-hero-thumb-6'>
                <div
                  className='thumb text-center wow animated fadeInUp'
                  data-wow-duration='1000ms'
                  data-wow-delay='600ms'
                >
                  <img src={image} alt='' style={{ width: 600 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UnderConstruction;
