import React from "react";
import useToggle from "../../hooks/useToggle";
import BackToTop from "lib/BackToTop";
import Drawer from "../Mobile/Drawer";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";
import Hero from "../../layout/Hero/Hero";
import SponserHomeTwo from "../../components/Sponser/Sponser";
import ServicesAbout from "../../components/Services/ServicesTwo";
import Faq from "../../components/Faq/FaqTwo";
import Testimonial from "../../components/Testimonial/Testimonial";
import CounterArea from "../../components/CounterArea/CounterArea";
import Features from "../../layout/Features/Features";
import Traffic from "../../components/Traffic/Traffic";
import Services from "../../components/Services/Services";

function About() {
  const [drawer, setDrawer] = useToggle(false);
  return (
    <>
      <Drawer drawer={drawer} action={setDrawer.toggle} />
      <Header drawer={drawer} action={setDrawer.toggle} />
      <Hero />

      <Services />
      <Features />
      {/* <CounterArea /> */}
      {/* <Testimonial /> */}
      {/* <ServicesAbout /> */}
      {/* <Traffic /> */}

      {/* <SponserHomeTwo /> */}
      <Faq />

      <Footer />
      <BackToTop className="back-to-top-8" />
    </>
  );
}

export default About;
