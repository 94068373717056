import React from "react";
import useToggle from "../../hooks/useToggle";
import BackToTop from "lib/BackToTop";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import SponserHomeTwo from "../../components/Sponser/Sponser";
import Drawer from "../Mobile/Drawer";
import HeroAbout from "../../layout/Hero/HeroTwo";
import ServicesAbout from "../../components/Services/ServicesTwo";
import Faq from "../../components/Faq/FaqTwo";

function AboutUs() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header drawer={drawer} action={drawerAction.toggle} />
      <HeroAbout />
      <ServicesAbout />
      <SponserHomeTwo />
      <Faq />
      <Footer />
      <BackToTop />
    </>
  );
}

export default AboutUs;
